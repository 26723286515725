<template>
  <div id="customer-projection">
    <vs-col>
      <vs-button
        style="float: right;top: -24px;right: -10px;"
        @click="$emit('close')"
        color="danger" type="gradient" icon="close"></vs-button>
      <div class="flex flex-wrap">
        <div class="px-2 py-3 rounded text-center"
             v-for="p in projections">
          <div class="bg-white shadow-lg px-4 py-2 rounded-lg">
            <p class="projection-title">{{ p.created | onlyDateFormat }}</p>
            <p class="projection-amount">{{ p.sale_amount | germanNumberFormat }}</p>
          </div>
        </div>
      </div>

      <div class="vs-row flex justify-center mt-3">
        <vs-button class="ml-4 mt-3" color="danger" @click="$emit('close')">{{ $t('Close') }}</vs-button>
      </div>
    </vs-col>
  </div>
</template>

<script>
export default {
  name: "CustomerProjectsComponent",
  components: {},
  props: {
    projections: {
      default: [],
    },
  }
}
</script>

<style lang="scss">
#customer-projection {
  .projection-title {
    font-size: 18px;
  }

  .projection-amount {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
